import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import Question from './Question';
import Section from './Section';

const StyledQuestionHeading = styled.h3`
  margin: 0 auto;
`;

const StyledPrejudiceHeading = styled.h4`
  margin-bottom: 0.5rem;
`;

const StyledPrejudice = styled.div`
  margin-bottom: 2rem;
`;

const StyledInfoContainer = styled.div`
  flex-direction: column-reverse;
  display: flex;
  gap: 1rem;

  @media (min-width: 768px) {
    gap: 4rem;
    flex-direction: row;
  }
`;

const StyledImageContainer = styled.div`
  @media (min-width: 768px) {
    width: 40%;
  }
`;

function Fragen() {
  return (
    <Section maxWidth={'90ch'} width='100%'>
      <StyledQuestionHeading>Fragen und Antworten</StyledQuestionHeading>
      <Question question='Wie wird Autismus festgestellt?'>
        <p>
          Die Diagnostik ist sehr komplex und wird von erfahrenen Spezialisten
          durchgeführt. Bei Kindern sind es z.B. Kinder- und Jugendpsychiater,
          Sozialpädiatrische Zentren oder auch spezialisierte Kinder- und
          Jugendpsychotherapeuten in Zusammenarbeit mit einem Kinder- und
          Jugendarzt, der entwicklungsneurologisch ausgebildet ist. Es gibt
          verschiedene Testinstrumente und Verfahren, die angewendet werden, um
          die jeweilige Diagnose zu stellen.
        </p>
      </Question>

      <Question question='Warum spricht man vom Autismus als Spektrum?'>
        <p>
          Wie der Begriff „Spektrum” schon sagt, gibt es nicht ein
          Erscheinungsbild des Autismus, sondern dieses ist sehr vielfältig und
          individuell. Wichtig zu beachten ist, dass jeder autistische Mensch
          unterschiedlich ist und so auch die Ausprägungen „seines Autismus”.
        </p>
      </Question>

      <Question question='Was ist der Frühkindliche Autismus?'>
        <p>
          Symptome des frühkindlicher Autismus zeigen sich bereits vor dem
          dritten Lebensjahr durch unter anderem Fehlen oder Verzögerung der
          Sprachentwicklung, rigide Verhaltensmuster und/oder eingeschränkte
          soziale Interaktion.
        </p>
      </Question>
      <Question question='Was ist das Asperger Syndrom?'>
        <p>
          Asperger Syndrom ist eine Form des Autismus. Die Betroffenen haben
          einen durchschnittlichen bis stark überdurchschnittlichen IQ. Häufig
          erhalten Kinder erst im Schulalter diese Diagnose und manche Personen
          erst im Erwachsenenalter.
        </p>
      </Question>
      <Question
        question='Sagt man autistisch, mit Autismus, Autismus-Spektrum-Störung,
Autist/Autistin?'
      >
        <p>
          Hierzu gibt es verschiedene Perspektiven. Fachleute in sozialen
          Berufen lernen häufig in ihrem Studium, dass es besser ist zuerst die
          „Person” und dann die „Identität” zu nennen, z.B. Mensch mit
          Behinderung, Mensch mit Autismus. Interessenverbände und
          Selbsthilfegruppen autistischer Menschen haben jedoch darauf
          aufmerksam gemacht, dass „Mensch mit Autismus” für viele wie eine
          Krankheit klingt. Wie etwas losgelöstes von ihrer Persönlichkeit, das
          aber nicht trennbar von ihnen ist oder sein sollte. Für andere spielt
          es wiederum überhaupt keine Rolle, wie man den Begriff nutzt. Die
          beste Strategie ist offen zu bleiben, zuzulassen sich auf individuelle
          Wünsche einzustellen und voneinander zu lernen.
        </p>
      </Question>
      <Question question='Wie viele autistische Menschen gibt es in Deutschland?'>
        <p>
          Es liegen keine konkreten Zahlen über die Häufigkeit von Autismus in
          Deutschland vor. Aktuell wird eine weltweite Prävalenz 0,6% – 1%
          angenommen (Stand 2021).
        </p>
      </Question>
      <Question question='Gibt es geschlechtsspezifische Unterschiede?'>
        <p>
          Viele Quellen besagen, dass mit einer Autismusquote von 4:1 deutlich
          mehr männliche Personen betroffen seien. Diese Zahlen sollten
          vorsichtig beurteilt werden. Weibliche Personen mit Asperger-Syndrom
          beispielsweise werden seltener diagnostiziert. Mögliche Gründe hierfür
          können sein, dass die Diagnostik eher jungen- bzw. männerspezifisch
          geprägt ist. Andere Quellen vermuten, dass Mädchen/Frauen sich besser
          an ihr Umfeld anpassen und kompensieren können und dadurch weniger
          auffallen.
        </p>
      </Question>
      <Question question='Gibt es mehr autistische Menschen als früher?'>
        <p>
          In den letzten Jahren wird die Hypothese einer deutlichen Zunahme der
          Autismusrate verbreitet. Diese Hypothese ist umstritten. Eine Zunahme
          der Zahlen könnten Umwelt- und Lebensbedingungen sein sowie neue und
          breiter gefasste Diagnosekriterien. Des Weiteren ist das Bewusstsein
          der Eltern, Ärzte oder anderer Fachpersonen gestiegen, so dass
          sensibler auf die Entwicklung der Kinder geschaut wird. Verschiedene
          Studien beschäftigen sich gerade mit dieser Thematik.
        </p>
      </Question>
      <Question question='Was ist die Ursache für Autismus?'>
        <p>
          Trotz umfangreicher Forschung ist die Ursache für das, was als
          Autismus bezeichnet wird, nicht abschließend geklärt. Genetische sowie
          neurophysiologische Ursachen gelten als wahrscheinlich.
          Wechselwirkungen zwischen Umweltfaktoren und Genen scheinen ebenfalls
          eine Rolle zu spielen.
        </p>
      </Question>
      <Question question='Ist Autismus etwas Schlechtes?'>
        <p>
          Autismus wird häufig als sehr negativ dargestellt und es werden oft
          nur die Einschränkungen beschrieben. Jedoch nimmt nicht jeder
          autistische Mensch „seinen Autismus” als etwas schlechtes wahr und
          viele beschreiben dadurch Vorteile und Stärken. Autismus sollte nicht
          als Synonym für geringe Lebensqualität oder ein unglückliches Leben
          gesehen werden. Häufig werden problematische Verhaltensweisen,
          fehlende Fähigkeiten oder eingeschränkte Möglichkeiten der Teilhabe
          mit Autismus gleichgesetzt, das muss aber nicht so sein.
        </p>
      </Question>
      <Question question='Ist Autismus heilbar?'>
        <p>
          Nein. Merkmale verändern sich natürlich mit dem Älterwerden. Autismus
          bleibt jedoch ein Leben lang bestehen. Es gibt Personen, die eine
          lebenslange Unterstützung benötigen und manche, die ein eigenständiges
          Leben führen. Das Maß und die Form an Unterstützung ist natürlich sehr
          unterschiedlich. Hinweis: wenn Therapien oder Arzneimittel versprechen
          Autismus zu heilen, ist das falsch und auch gefährlich, da es
          autistischen Menschen und ihren Angehörigen schaden kann. Des Weiteren
          fördert es das Bild, dass Autismus eine Krankheit ist, die es zu
          heilen gilt. Stattdessen sollten Kompetenzen erweitert, Fähigkeiten
          gefördert und Chancen ermöglicht werden.
        </p>
      </Question>
      <Question question='Braucht jeder Mensch mit Autismus eine Therapie?'>
        <p>
          Nein. Das hängt ganz davon ab, ob die Person selbst oder die
          Sorgeberechtigten der Meinung sind, dass eine Therapie hilfreich wäre,
          zum Beispiel um neue Fähigkeiten zu erlernen oder weil große
          Einschränkungen oder Probleme bestehen. Oft empfehlen auch Ärzt:innen
          eine autismusspezifische Therapie, deren Inhalte und Art sehr
          unterschiedlich ausfallen können.
        </p>
      </Question>

      <Section width='100%' maxWidth='100%'>
        <StyledInfoContainer>
          <h2>Vorurteile, aufgepasst…</h2>
          <StyledImageContainer>
            <StaticImage
              src='../images/Vorurteile.png'
              alt='Schubladen denken'
            />
          </StyledImageContainer>
        </StyledInfoContainer>
      </Section>

      <StyledPrejudice>
        <StyledPrejudiceHeading>
          Autistische Menschen wollen gar keine sozialen Kontakte{' '}
        </StyledPrejudiceHeading>
        <p>
          Viele autistische Menschen haben das Bedürfnis nach sozialen
          Kontakten. Ihr Verhalten wird jedoch häufig fehlinterpretiert, da sie
          soziale Interaktionen auf eine andere Art führen, als allgemein
          erwartet oder sie nicht aktiv den Kontakt aufnehmen (können).
        </p>
      </StyledPrejudice>
      <StyledPrejudice>
        <StyledPrejudiceHeading>
          Menschen mit Autismus haben keine Gefühle
        </StyledPrejudiceHeading>
        <p>
          Das ist falsch. Autistische Menschen können dasselbe Spektrum an
          Gefühlen wie nicht-autistische Personen fühlen, nur zeigen sie es
          eventuell auf eine andere Art, als die Gesellschaft es gewohnt ist.
        </p>
      </StyledPrejudice>
      <StyledPrejudice>
        <StyledPrejudiceHeading>
          Autistische Menschen sind unempathisch
        </StyledPrejudiceHeading>
        <p>
          Autistische Menschen haben Empathie. Nicht jede autistische Person
          kann die Emotion einer anderen Person erkennen und dementsprechend
          handeln. Dadurch können Missverständnisse entstehen und andere
          Personen denken, die autistische Person sei gefühlskalt.
        </p>
      </StyledPrejudice>
      <StyledPrejudice>
        <StyledPrejudiceHeading>
          Autistische Menschen sind hochbegabt
        </StyledPrejudiceHeading>
        <p>
          Autistische Menschen können hochbegabt sein, wie auch
          nicht-autistische Menschen, jedoch ist nicht jeder Autist gleich auch
          hochbegabt. Dieses Vorurteil belastet viele autistische Menschen, da
          sie dadurch mit Erwartungen konfrontiert werden, die nicht jeder/jede
          erfüllt.
        </p>
      </StyledPrejudice>
      <StyledPrejudice>
        <StyledPrejudiceHeading>
          Autistische Menschen, die nicht sprechen, verstehen auch nichts
        </StyledPrejudiceHeading>
        <p>
          Es gibt autistische Menschen, die nicht durch Worte kommunizieren. Das
          bedeutet jedoch nicht, dass sie andere Menschen nicht verstehen. Das
          Sprachverständnis kann eingeschränkt sein, jedoch sollte man
          vorsichtig damit sein anzunehmen, dass die Person einen nicht
          versteht.
        </p>
      </StyledPrejudice>
      <StyledPrejudice>
        <StyledPrejudiceHeading>
          Autismus ist ein Problem der Erziehung
        </StyledPrejudiceHeading>
        <p>
          Leider werden Eltern noch immer mit dem Vorurteil konfrontiert, dass
          Autismus ein Problem der Erziehung sei. Dies ist nicht der Fall.
        </p>
      </StyledPrejudice>
      <StyledPrejudice>
        <StyledPrejudiceHeading>
          Autistische Kinder können nicht lernen
        </StyledPrejudiceHeading>
        <p>
          Vor allem Eltern, deren Kinder früh mit Autismus diagnostiziert
          werden, bekommen häufiger das Gefühl vermittelt, ihr Kind werde nicht
          lernen oder sich in irgendeiner Weise weiterentwickeln können. Das
          verletzt nicht nur die Gefühle der Eltern und sicher auch der Kinder,
          sondern schafft Grenzen und Einschränkungen, wo keine sein sollten.
          Kinder mit Autismus können natürlich lernen, Fähigkeiten erwerben und
          sich weiterentwickeln. Jedes Kind in seinem eigenen Tempo und, wenn
          das Umfeld bereit ist geeignete Lernbedingungen zu schaffen.
        </p>
      </StyledPrejudice>
      <StyledPrejudice>
        <StyledPrejudiceHeading>
          Autistische Menschen sind geistig behindert
        </StyledPrejudiceHeading>
        <p>
          Es gibt Menschen mit Autismus, die auch eine geistige Behinderung
          haben, aber nicht alle autistische Menschen haben eine geistige
          Behinderung.
        </p>
      </StyledPrejudice>
      <StyledPrejudice>
        <StyledPrejudiceHeading>
          Autisten sind sehr intelligent
        </StyledPrejudiceHeading>
        <p>
          Das Intelligenzniveau bei autistischen Menschen kann unterschiedlich
          sein. Grundsätzlich fällt auf, dass es schwierig sein kann den IQ
          festzustellen. IQ Testungen sind häufig nicht auf die Bedürfnisse von
          autistischen Personen angepasst, so dass z.B. Aufgabenstellungen nicht
          verstanden werden, das Mitmachen durch fremde Personen und Umgebungen
          beeinträchtigt wird oder Einschränkungen in der Sprache die Aufgaben
          erschweren.
        </p>
      </StyledPrejudice>
      <StyledPrejudiceHeading>Was kann helfen?</StyledPrejudiceHeading>
      <p>
        Natürlich ist kein Mensch völlig frei von Erwartungen an andere. Werden
        unsere Erwartungen nicht erfüllt, dann sollten wir jedoch dem, was wir
        (noch) nicht kennen, offen und wertfrei begegnen. Lasst uns also mutig
        sein, von- und miteinander zu lernen.
      </p>
    </Section>
  );
}

export default Fragen;
