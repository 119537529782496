import React from 'react';
import Fragen from '../components/fragen';
import Section from '../components/Section';
import Metadata from '../components/Metadata';
import styled from 'styled-components';

const StyledAutismSectionContainer = styled.div`
  margin-bottom: 2rem;
  margin-top: 2rem;
  display: grid;
  gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  h3 {
    margin-bottom: 1rem;
  }
`;

function Autismus() {
  return (
    <>
      <Metadata title='Autismus' />
      <Section heading='Autismus' maxWidth='90ch'>
        <StyledAutismSectionContainer>
          <div style={{ marginBottom: '1rem' }}>
            <h3>Medizinisch betrachtet</h3>
            <p>
              Die Autismus-Spektrum-Störung (ASS) gehört zu den tiefgreifenden
              Entwicklungsstörungen und ist charakterisiert durch
              Auffälligkeiten sowohl in der sozialen Interaktion als auch in der
              verbalen und nonverbalen Kommunikation. Neurobiologische
              Untersuchungen zeigen, dass sich die Entwicklung und die Struktur
              autistischer Gehirne von denen nicht-autistischer Menschen
              unterscheiden.
            </p>
            <p>
              Gemäß des Klassifikationssystems ICD-10 (WHO, 1992) wird im
              Bereich der Autismus-Spektrum-Störungen in Frühkindlicher
              Autismus, Asperger-Syndrom und Atypischer Autismus unterschieden.
              Die aktualisierte Version die ICD-11 (WHO, 2019) und das DSM-5
              (APA, 2013) fasst verschiedene Ausprägungen unter der Diagnose
              Autismus-Spektrum-Störung zusammen.
            </p>
            <p>
              Um eine entsprechende Diagnose zu erhalten, muss eine bestimmte
              Anzahl folgender Kriterien erfüllt sein:
            </p>
            <ul>
              <li>
                Defizite in der sozialen Interaktion und (sozialen)
                Kommunikation
              </li>
              <li>restriktive, repetitive Verhaltensweisen</li>
              <li>eingeschränkte Interessen </li>
              <li>bestehen der Symptome seit der frühen Kindheit </li>
              <li>
                eine bedeutsame Beeinträchtigung in alltäglichen
                Funktionsbereichen durch die Symptome
              </li>
            </ul>
          </div>
          <div>
            <h3>Sozial betrachtet</h3>
            <p>
              Der Begriff „Störung” wirkt auf viele autistische Personen
              abwertend und nicht zutreffend. Aus diesem Grund haben sich seit
              den letzten Jahrzehnten immer mehr autistische Menschen dafür
              ausgesprochen die Abweichung von der Norm oder ein „Anderssein”
              nicht als Störung zu bezeichnen, sondern als Besonderheit. So
              entstand auch der Begriff „neurotypisch” (NT), als Gegenkonzept
              von „autistisch”. Als neurotypisch werden Personen bezeichnet
              deren Wahrnehmung und Verhalten dem entspricht, was in der
              Gesellschaft als „normal“ angesehen wird.
            </p>
            <p>
              Die Grundlagen der Diagnose bilden aktuell die Kriterien der
              Klassifikationssysteme für Krankheiten und verwandte
              Gesundheitsprobleme (siehe ICD und DSM). Diese legen den Fokus auf
              das Verhalten und die Defizite der Personen. Interessenverbände
              und Gemeinschaften autistischer Menschen orientieren sich bei der
              Beschreibung von Autismus jedoch mehr an dem Erleben. Autismus
              wird als eine andere Weise der Kommunikation und soziale Kontakte
              zu führen, des Denkens, der Wahrnehmungsverarbeitung, andere
              Bewegungsmuster, beschrieben. Interessenverbände und
              Gemeinschaften autistischer Menschen fordern daher, dass das
              Erleben und die Sichtweisen autistischer Menschen anerkannt werden
              und auch als Grundlage des Verständnis von Autismus dienen.
            </p>
          </div>
        </StyledAutismSectionContainer>
        <p>
          Das soziale Umfeld, in welchem autistische Menschen aufwachsen und
          leben, prägen ihr Verhalten und auch ihre Selbstwahrnehmung. Begegnen
          wir unserem Gegenüber mit einer Haltung der Ablehnung, weil
          Verhaltensweisen nicht dem entsprechen, was wir erwarten, macht das
          etwas mit einer Person. Wir können aktiv beeinflussen, wie wir
          Menschen begegnen, deren Verhalten uns neu erscheint und was wir aus
          dieser Begegnung mitnehmen. Das Leben in einer einer Gesellschaft
          beinhaltet natürlich, dass jede Person sich mal mehr und mal weniger
          an sein Umfeld anpasst. An wen, wann und wie oft das passiert, sollte
          jedoch nicht einseitig erwartet werden und ausbalanciert bleiben.
        </p>
        <Fragen></Fragen>
      </Section>
    </>
  );
}

export default Autismus;
