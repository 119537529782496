import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const StyledQuestion = styled.div`
  display: flex;
  cursor: pointer;

  &:hover {
    color: var(--color-primary);
  }
`;

const StyledQuestionContainer = styled.div`
  margin-bottom: 1rem;

  p {
    margin: 0;
  }

  h4 {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
`;

const StyledQuestionText = styled.span`
  margin-right: 0.5rem;
`;

function Question({ question, children }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <StyledQuestionContainer>
      <StyledQuestion onClick={() => setOpen(!isOpen)}>
        <h4>
          <StyledQuestionText>{question}</StyledQuestionText>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown}>
            Icon
          </FontAwesomeIcon>
        </h4>
      </StyledQuestion>
      {isOpen && <> {children} </>}
    </StyledQuestionContainer>
  );
}

export default Question;
